@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/alert";

// hack
.alert.flex-row .fa {
  font-size: 24px;
  margin-right: 12px;
}

.Anonymous {
  margin-left: 5px;
  margin-right: 5px;
  display: block;
  color: black;
  background-color: #ffaea9;
  border: 1px solid #d38c87;
}
