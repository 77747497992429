.ErrorBoundary {
  color: #dc3545;

  &__Warning.fa {
    font-size: 5em;
    margin-bottom: 20px;
  }

  .btn-primary {
    color: white;
    font-size: 1.2em;
  }

  details {
    margin-top: 15px;
    white-space: pre-wrap;
  }

  details[open] {
    overflow-y: scroll;
    max-height: 500px;
  }
}
