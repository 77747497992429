@import "./variables";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/print";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/utilities/display";
@import "~bootstrap/scss/utilities/flex";
@import "~bootstrap/scss/utilities/spacing";
@import "~bootstrap/scss/images";

// flex helpers
@mixin flex($num) {
  flex: $num;
  box-sizing: border-box;
}

@for $i from 1 through 12 {
  .flex-#{$i} {
    @include flex($i);
  }
}

.flex-shrink {
  flex-shrink: 1;
}

.flex-grow {
  flex-grow: 1;
}

.flex-no-shrink {
  flex-shrink: 0;
}

.flex-no-grow {
  flex-grow: 0;
}

.flex-overflow-scroll {
  overflow: auto;
}

// Some best-practice CSS that's useful for most apps
// Just remove them if they're not what you want
html {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-size: 12px;
}

html,
body,
.viewport,
#root,
.noscript {
  width: 100%;
  height: 100%;
  margin: 0;
}

#root {
  display: flex;
  flex: 1;
}

#appRoot {
  margin-top: 20px;
}

#appRoot > .container {
  min-height: 400px;
}

.schema-field-ui-select {
  width: 100%;
}

noscript,
.noscript {
  display: flex;
  flex: 1;

  .alert {
    margin-bottom: 0;
    font-family: helvetica, arial, sans-serif;
    padding: 4px;
    font-weight: bold;
    text-align: center;
    border-radius: 0;

    .fa {
      font-size: 8em;
    }
  }
}

.modal-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transition: transform 0s !important;

  //padding: 20px;

  min-width: 320px;
}

.overflow-hidden {
  overflow: hidden;
}

.mt--1 {
  margin-top: -1rem;
}

.mt--2 {
  margin-top: -2rem;
}

.normal {
  font-weight: normal;
  font-size: inherit;
}

.red {
  color: red;
}

.bold {
  font-weight: bold;
}

.center {
  text-align: center;
}

.font-small {
}

.silver {
  color: gray;
}

.rdt.rdtStatic.rdtOpen {
  display: inline-block;
  position: relative;
}

.rdtPicker.rdtPicker td.rdtToday:before {
  border-bottom-color: $primary;
}

.rdtPicker.rdtPicker td.rdtActive,
.rdtPicker.rdtPicker td.rdtActive:hover {
  background-color: $primary;
}

@import "./Alert.scss";
@import "./Toastr.scss";
@import "./Tooltip.scss";

.app-modal-overlay {
  z-index: 123456789;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;

  background-color: transparentize(silver, 0.6);

  & > div {
    //background-color: white;
    //border: 1px solid silver;

    @extend .alert;
    @extend .alert-warning;

    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: transform 0s !important;

    //padding: 20px;

    min-width: 320px;

    z-index: 100;

    font-size: 20px;
  }
}

[role="button"] {
  cursor: pointer;
}

.ReactModal {
  /*
{
  overlay : {
    position          : 'fixed',
    top               : 0,
    left              : 0,
    right             : 0,
    bottom            : 0,
    backgroundColor   : 'rgba(255, 255, 255, 0.75)'
  },
  content : {
    position                   : 'absolute',
    top                        : '40px',
    left                       : '40px',
    right                      : '40px',
    bottom                     : '40px',
    border                     : '1px solid #ccc',
    background                 : '#fff',
    overflow                   : 'auto',
    WebkitOverflowScrolling    : 'touch',
    borderRadius               : '4px',
    outline                    : 'none',
    padding                    : '20px'
  }
}
   */
}

label {
  cursor: pointer;
}

.bt-1 {
  border-top: 1px solid silver;
}
